// FONT

@import url('https://fonts.googleapis.com/css2?family=Kreon:wght@300..700&display=swap');

// Lichterkinder <- Neumeier <- kaffkiez <- JORIS SKIN <- FE SKIN <- BF SKIN <- JIRAFA SKIN

body {
  background-color: $white;
  font-family: $lkfont;
  color: $basetext;
}

#root {
  padding-top: 0;
}

.btn,
.loading-spinner-content {
  text-transform: uppercase;
}

.btn.btn-primary {
  background-color: $lkpink;
  border-color: $lkpink;
}

.btn.btn-secondary {
  background-color: $lkblue;
  border-color: $lkblue;
}

// NAVIGATION

.navbar-light {
  background-color: rgba($color: $white, $alpha: 0.9) !important;
  color: $white;
}
#header {
  box-shadow: $box-shadow-xl;
  .nav-link.active {
    color: $lkblue;
    text-decoration: underline;
    text-decoration-color: #f5a700;
    text-underline-offset: 0.5rem;
  }
  .nav-link,
  i {
    color: $lkpink;
    font-weight: bold;
  }
  //height: $calculated-navbar-height-sm;
  //@include media-breakpoint-up(md) {
  //height: $calculated-navbar-height;
  //}

  // big screens
  .main-menu {
    // hide the main menu hamburger
    display: none !important;
    flex-grow: 0;
  }

  // small screens
  @include media-breakpoint-down(lg) {
    .navbar-brand-container {
      flex-grow: 1;
    }

    .main-menu {
      display: flex !important;
      padding-left: 1rem;
    }

    .navbar .secondary-menu {
      //justify-content: space-between !important;
      padding-left: 0;

      .menu {
        // hide category links
        display: none;
      }
    }
    .flex-nowrap {
      justify-content: space-between;
    }
  }

  .navbar {
    padding: 0.5rem;
    font-size: $h5-font-size;

    .navbar-brand {
      padding: 0;

      img {
        //margin-bottom: 0.5rem;
        height: 3rem;
        max-height: 3rem;
        @include media-breakpoint-up(sm) {
          height: 3rem;
          max-height: 3rem;
        }
        @include media-breakpoint-up(md) {
          height: 5rem;
          max-height: 5rem;
        }
        &:hover,
        &:focus {
          filter: opacity(0.66);
        }
      }
    }
  }
}

.navbar {
  .hamburger {
    color: $lkred !important;
    @include media-breakpoint-down(sm) {
      padding-left: 0px;
    }
    .hamburger-inner,
    .hamburger-inner:after,
    .hamburger-inner:before {
      height: 1px;
    }
    &.is-active {
      .hamburger-inner,
      .hamburger-inner:after,
      .hamburger-inner:before {
        background-color: $lkred !important;
      }
    }
  }
  .secondary-menu,
  .main-menu {
    display: flex;
    align-items: center;
    flex-grow: 1;
    //text-transform: uppercase;
    //margin-left: 20px;
    //min-width: 70px !important;
  }
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

.navbar .nav-item.last {
  .nav-link {
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}

.navbar .nav-link .badge.expiration {
  display: none;
  @media only screen and (min-width: 350px) {
    display: inherit;
  }
}

.badge-secondary,
.badge-info {
  background-color: $lkblue;
}

.offcanvas-collapse {
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: $white !important;
    border: 0.5px solid black;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    font-size: $h5-font-size;
    //text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
    a {
      color: $white;
    }
    a.active,
    a:hover {
      color: $gray-300;
    }
  }
}

.offcanvas-collapse {
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background: rgba($color: $gray-100, $alpha: 0.9) !important;
  backdrop-filter: blur(5px);
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

.shoppingcart-finisher,   // bottom border mit 'direkt zur Kasse' Button
.sticky-bottom.is-valid {
  // aber auch der sticky beim kaufabschluss
  background: rgba($color: $lkgreen, $alpha: 0.8) !important;
  backdrop-filter: blur(5px);
}

// MAKI

.maki {
  @extend .p-1;
  .card-body {
    flex-direction: column-reverse;
    background-color: $white;
    transition: 0.5s ease-out;
  }
  .card-title {
    text-align: center;
    padding-left: 10px;
    color: $basetext;
    .title {
      //text-transform: uppercase;
      font-size: $h4-font-size;
      font-weight: 900 !important;
    }
    .sub-title {
      font-size: smaller;
      @extend .mb-2;
    }
  }
  &:hover {
    .card-body {
      background-color: $white;
      .card-title {
        color: $lkgreen;
      }
    }
  }
}

// LISTING HEADER

.ticket-listing,
.product-listing-page,
.category-detail {
  h1 {
    color: $lkblue;
    text-decoration: underline;
    text-decoration-color: $lkyellow;
    text-underline-offset: 1rem;
    text-align: center;
    font-size: 3rem;
    transform: rotate(-3deg);
  }
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid $lkyellow;
  border-radius: none;

  .nigiri-content {
    padding: 1.25rem 0;
    padding-top: 0;
    margin-top: 0.2rem;
    color: $basetext;
    @include media-breakpoint-down(md) {
      display: table;
    }

    .sold-out-status_sold_out {
      z-index: 2;
      transform: rotate(2.5deg);
      font-size: 0.8em;
    }

    .nigiri-date {
      font-size: $h3-font-size;
      color: $lkgreen;
      font-weight: 700;
    }

    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      //text-transform: uppercase;
      font-weight: bold;
      p {
        margin-bottom: 0px;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
        padding-left: 0;
        line-height: 2rem;
      }

      .title {
        font-size: 0.6em;
        margin-bottom: 0;
        color: $basetext;
      }

      .location {
        font-size: $h3-font-size !important;
        font-weight: 700;
        color: $basetext;
      }

      .nigiri-tags {
        display: block !important;
        font-size: 1rem;
        text-transform: none;
        .tag {
          margin-right: 5px;
        }
      }
    }

    .nigiri-cta .btn {
      color: $white;
      background: $lkpink;
      border: 2px solid $lkpink;
      &:hover {
        background-color: $white;
        color: $lkpink;
        border: 2px solid $lkpink;
      }
    }
  }
  // .subtitle {
  //   font-size: 0.5em;
  //   margin-bottom: 0;
  //   color: $gray-500;
  //   font-weight: normal;
  //   @include media-breakpoint-down(md) {
  //     line-height: 1rem;
  //     margin-bottom: 0.5rem;
  //   }
  // }
}

// Ticket Detail Page

.ticket-detail-page {
  .ticket-detail .product-header > .container {
    width: 100% !important;
    max-width: none !important;
    padding: 0 !important;
    margin: 0;
  }

  .card {
    border: none;
    border-radius: 0px;
  }
  .card.article-title {
    background-color: $white;
    //text-transform: uppercase;
    .super-title {
      color: $lkred;
    }
    h1 {
      color: $lkblue;
      font-size: $h2-font-size;
      font-weight: 700 !important;
      @include media-breakpoint-up(sm) {
        font-size: $h1-font-size;
      }
      font-weight: 500;
      small {
        color: $lkyellow;
      }
    }
  }
}

// Product Detail Page

.detail-page {
  h1 {
    margin-top: 0;
    color: $lkblue;
    //text-transform: uppercase;
    font-size: $h2-font-size;
    .sub-title {
      color: $lkyellow;
      font-size: $h3-font-size;
    }
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: rgba($color: $white, $alpha: 0.9) !important;
}

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  min-height: 30vh;
  background-color: $basetext;
  color: #f8f8f8;
  a {
    color: #f8f8f8;
  }
  img {
    //max-height: 4rem;
    filter: invert(1);
  }
  // border-bottom: 4px solid $primary;
}

// CHECKOUT

.checkout .sticky-bottom {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
}

// OTHER

#product-listing {
  padding-top: 2rem;
}
.hero-slider {
  margin-bottom: 0;
}

.stage-wrapper {
  box-shadow: $box-shadow-xl;
  background-color: #fbccb0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.stage {
  box-shadow: $box-shadow-xl;
}

.boxheader {
  text-transform: uppercase;
  text-align: center;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

// FIXES

.tt-sticky-top {
  top: 0;
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}
